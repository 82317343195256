
export default `
## Education

**University of Washington, Seattle — BS in Informatics**

_September 2013 - June 2017_

> My area of study focused on Web Development and Data Science. I worked on many individual and team projects where we built full web applications

---
`;
